<template>
	<div class="product-details">
		<div class="product-details__navbar">
			<Navbar />
		</div>
		<div class="product-details__body">
			<div class="product-details__body-top">
				<ProductDetailsMain :product="product" :exhibitorLogo="exhibitorLogo" :defaultPhoto="defaultPhoto" />
			</div>
			<div class="product-details__body-bottom">
				<div class="product-details__body-bottom-right-top" style="display: none;">
					<ProductDetailsFeatures :product="product" />
				</div>
				<div class="product-details__body-bottom-left" style="width: 66%">
					<ProductDetailsDescription :product="product" />
					<ProductDetailsDocuments :product="product" />
					<ProductDetailsProducts :product="product" :products="products" />
				</div>
				<div class="product-details__body-bottom-right" style="width: 34%; padding-left: 2.5rem">
					<ProductDetailsFeatures :product="product" />
				</div>
			</div>
		</div>
		<footer class="product-details__footer">
			<Footer />
		</footer>
	</div>
</template>

<script>
import Navbar from '@/components/navbar/Navbar.vue';
import Footer from '@/components/footer/Footer.vue';
import { getDocument } from '@/api/common.js';
import { getProductInfo } from '@/api/product.js';
import ProductDetailsMain from '@/components/products/ProductDetailsMain.vue';
import ProductDetailsDescription from '@/components/products/ProductDetailsDescription.vue';
import ProductDetailsFeatures from '@/components/products/ProductDetailsFeatures.vue';
import ProductDetailsDocuments from '@/components/products/ProductDetailsDocuments.vue';
import ProductDetailsProducts from '@/components/products/ProductDetailsProducts.vue';
import { search, saveLogsAccess, receiveDateNow } from '@/api/common.js';
import { getProductsInterests } from '@/api/interest.js';

export default {
	name: 'ProductDetails',
	title() {
		return `${this.$t('menu.productmarketplace')} | ${this.$store.state.eventInfo.evtrname}`;
	},
	components: {
		Navbar,
		ProductDetailsMain,
		ProductDetailsDescription,
		ProductDetailsFeatures,
		ProductDetailsDocuments,
		ProductDetailsProducts,
		Footer
	},
	data() {
		return {
			product: {},
			exhibitorLogo: null,
			defaultPhoto: null,
			products: {},
			interestsProducts: [],
			user: {}
		};
	},
	mounted() {
		this.getDefaultPhoto();
		this.checkLoginAndGetInterests();
		this.insertLogsAccess();
	},

	watch: {
		product: 'getExhibitorLogo',

		'$store.state.activeLanguage'(newVal) {
			if (newVal) {
				this.checkLoginAndGetInterests();
			}
		}
	},

	methods: {
		async getProduct() {
			const id = this.$route.params.id;
			let data = await getProductInfo(id);
			this.product = data;
			this.getIfProductIsFav();
			this.getExhibitorsBySectors();
		},

		async getExhibitorsBySectors() {
			const body = {
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							data: this.$store.state.eventInfo.evenid,
							field: 'event',
							op: 'eq'
						},
						{
							data: this.product.prsrsectorid,
							field: 'sector',
							op: 'eq'
						}
					]
				},
				model: 'catalogproductservice',
				order: [
					{
						column: 'name',
						direction: 'asc'
					}
				],
				page: 1,
				queryFields: ['name'],
				queryText: '',
				rows: 999999999
			};
			const data = await search(body);
			this.products = data.data;
			this.shuffle(this.products);
			console.log(this.products);
			this.getIfProductsIsFav();
		},

		shuffle: function (array) {
			for (let i = array.length - 1; i > 0; i--) {
				let j = Math.floor(Math.random() * (i + 1));
				[array[i], array[j]] = [array[j], array[i]];
			}
		},

		checkLoginAndGetInterests() {
			this.checkLoginData();
			if (this.isLogged) {
				// lo ponemos a false para que los cards no existan
				this.gotUserExhibitorInterests = false;
				this.user = JSON.parse(localStorage.getItem('pui9_session'));
				this.getUserProductInterests();
			} else {
				this.getProduct();
			}
		},

		checkLoginData() {
			if (localStorage.getItem('pui9_session') !== null) {
				this.isLogged = true;
			} else {
				this.isLogged = false;
			}
		},
		async getUserProductInterests() {
			if (this.isLogged) {
				var params = {
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								data: this.$store.state.eventInfo.evenid,
								field: 'exhieventid',
								op: 'eq'
							},

							{
								data: this.user.email, //CORREO DEL USUARIO
								field: 'intevisitorid',
								op: 'eq'
							}
						]
					},

					order: [
						{
							column: 'interest',
							direction: 'asc'
						}
					],
					page: 1,
					queryFields: ['interest'],
					queryText: '',
					rows: 999999
				};

				let data = await getProductsInterests(params);
				this.interestsProducts = data;
				this.getProduct();
			}
		},

		getIfProductsIsFav() {
			this.products.forEach((product) => {
				product.fav = false;
				for (let x = 0; x < this.interestsProducts.length; x++) {
					if (this.interestsProducts[x].inteproductserviceid === product.productservice) {
						product.fav = true;
						this.favBtnProductSelected = true;
					}
				}
			});
			console.log(this.products);

			this.favBtnProductSelected = false;
		},

		getIfProductIsFav() {
			this.product.fav = false;
			for (let x = 0; x < this.interestsProducts.length; x++) {
				if (this.interestsProducts[x].inteproductserviceid === this.product.prsrid) {
					this.product.fav = true;
					this.favBtnProductSelected = true;
				}
			}
		},

		getExhibitorLogo: async function () {
			const data = await getDocument('exhibitor', this.product.prsrexhibitorid, 'exhibitor_logo');
			if (data[0]) this.exhibitorLogo = data[0].url;
		},

		getDefaultPhoto: async function () {
			const data = await getDocument('event', this.$store.state.eventInfo.evenid, 'event_app_primary');
			this.defaultPhoto = data[0].url;
		},

		async insertLogsAccess() {
			const user = JSON.parse(localStorage.getItem('pui9_session'));
			
			let data = await getProductInfo(this.$route.params.id);
			
			saveLogsAccess({
				loacfecha: receiveDateNow(),
				loacusr: user ? user.usr : 'anonymous',
				loacfuncionalidad: "Detalle de producto",
				loaceventgroupid: this.$store.state.eventInfo.eveneventgroupid,
				loacurl: this.$route.path,
				loacip: user ? user.lastLoginIp : '0.0.0.0',
				loacenvironment: "CATÁLOGO",
				loacproductserviceid: this.$route.params.id,
				loacexhibitorid: data.prsrexhibitorid
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.product-details {
	display: flex;
	flex-direction: column;
	width: 100%;

	&__navbar,
	&__footer {
		flex: none;
	}
	&__body {
		overflow-y: hidden;
		flex: auto;
	}
}

.product-details__body {
	padding: 1.75rem 8.125rem;
	@media only screen and (max-width: 960px) {
		padding: 1.75rem 2rem;
	}

	&-bottom {
		display: flex;
		@media only screen and (max-width: 960px) {
			display: contents;
		}
	}
}

.product-details__body-bottom-left {
	@media only screen and (max-width: 960px) {
		width: 100% !important;
	}
}

.product-details__body-bottom-right-top {
	@media only screen and (max-width: 960px) {
		width: 100% !important;
		padding-left: 0px !important;
		display: contents !important;
	}
}

.product-details__body-bottom-right {
	@media only screen and (max-width: 960px) {
		display: none;
	}
}
</style>
