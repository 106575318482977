<template>
	<div v-if="product.prsrdescription" class="product-details-description">
		<p v-html="product.prsrdescription" class="product-details-description-text"></p>
	</div>
</template>

<script>
export default {
	name: 'ProductDetailsDescription',

	props: {
		product: {}
	},

	components: {}
};
</script>


<style lang="scss" scoped>
.product-details-description {
	&-text {
		font-weight: normal;
		font-size: 20px;
		color: #575e60;
		padding: 2rem 0;
	}
}
</style>
