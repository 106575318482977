<template>
	<div v-if="product.pstrfeatures || product.pstrweb" class="product-details-features">
		<span v-if="product.pstrfeatures" class="product-details-features-section">{{ $t('products.features') }}</span>
		<span v-html="product.pstrfeatures" v-if="product.pstrfeatures" class="product-details-features-content"></span>
		<span v-if="product.pstrweb" class="product-details-features-section" style="padding-top: 1rem">Website</span>
		<a v-if="product.pstrweb" class="product-details-features-web" :href="'//' + returnWebsite(product.pstrweb)" target="_blank">
			{{ returnWebsite(product.pstrweb) }}
		</a>
	</div>
</template>

<script>
export default {
	name: 'ProductDetailsFeatures',

	props: {
		product: {}
	},

	components: {},

	methods: {
		returnWebsite: function (website) {
			try {
				const url = new URL(website);
				website = url.href.replace(/(^\w+:|^)\/\//, '');
				//return website.slice(0, -1);
				return website;
			} catch (_) {
				return website;
			}
		}
	}
};
</script>


<style lang="scss" scoped>
.product-details-features {
	background: #f3f5f5;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	padding: 1.25rem;

	&-section {
		font-family: Cabin;
		font-weight: bold;
		font-size: 1rem;
		color: #575e60;
	}
}
</style>
