<template>
	<div class="product-details-main">
		<div class="product-details-main__breadcrums">
			<router-link :to="{ name: 'Home' }" class="product-details-main__breadcrums-link">{{ $t('exhibitors.home') }}</router-link>
			<span class="product-details-main__breadcrums-separator">/</span>
			<router-link :to="{ name: 'Products' }" class="product-details-main__breadcrums-link">{{ $t('menu.productmarketplace') }}</router-link>
		</div>
		<div class="product-details-main__container">
			<div class="product-details-main__container-top">
				<div class="product-details-main__container-top-left">
					<div class="product-details-main__container-top-left-logo">
						<img v-if="exhibitorLogo" class="product-details-main__container-top-left-logo-image" :src="exhibitorLogo" alt="logo" />
						<img v-else class="product-details-main__container-top-left-logo-image" :src="defaultPhoto" alt="logo" />
					</div>
					<div class="product-details-main__container-top-left-text">
						<h1 class="product-details-main__container-top-left-text-title" v-html="product.pstrname"></h1>
						<h2 v-if="sector && sector.length" class="product-details-main__container-top-left-text-subtitle">
							{{ sector[0].setrname }}
						</h2>
					</div>
				</div>
				<div class="product-details-main__container-top-right">
					<Modal :product="product" favblue />
					<router-link :to="{ name: 'ExhibitorDetails', params: { id: product.prsrexhibitorid } }" style="text-decoration: none">
						<button class="product-details-main__container-top-right-button product-details-main__container-top-right-button--white">
							{{ $t('exhibitors.visit') }}
						</button>
					</router-link>
					<ShareDropdown outlined />
				</div>
			</div>
			<div class="product-details-main__container-middle">
				<iframe
					v-if="product.pstrvideo && embedVideo(product.pstrvideo)"
					:src="embedVideo(product.pstrvideo)"
					width="889"
					height="500"
					frameborder="0"
					allow="fullscreen"
					style="border-radius: 8px"
				></iframe>
				<v-carousel v-else-if="carousel" hide-delimiter-background style="border-radius: 8px">
					<v-carousel-item v-for="item in carousel" :key="item.id" :src="item.url"></v-carousel-item>
				</v-carousel>
				<img v-else-if="photo" class="product-details-main__container-middle-image" :src="photo.url" alt="foto" />
				<img v-else class="product-details-main__container-middle-image" :src="defaultPhoto" alt="foto" />
			</div>
		</div>
	</div>
</template>

<script>
import { getDocument } from '@/api/common.js';
import ShareDropdown from '@/components/common/ShareDropdown.vue';
import Modal from '@/components/common/Modal.vue';
// import { getSectorName } from '@/api/sector.js';
import { search } from '@/api/common.js';

export default {
	name: 'ProductDetailsMain',

	props: {
		product: {},
		exhibitorLogo: {
			type: String,
			default: null
		},
		defaultPhoto: {
			type: String,
			default: null
		}
	},

	components: {
		ShareDropdown,
		Modal
	},

	data() {
		return {
			carousel: null,
			photo: null,
			sector: {}
		};
	},
	watch: {
		product: 'getSectorByName'
	},
	mounted() {
		this.getPhoto();
	},

	methods: {
		getSectorByName: async function () {
			console.log(this.product);
			const body = {
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							data: this.product.prsrsectorid,
							field: 'sectid',
							op: 'eq'
						}
					]
				},
				model: 'sector',
				order: [
					{
						column: 'name',
						direction: 'asc'
					}
				],
				page: 1,
				queryFields: ['name'],
				queryText: '',
				rows: 999999999
			};

			let data = await search(body);
			this.sector = data.data;
			console.log(this.sector);
			console.log(this.product);
		},

		getPhoto: async function () {
			const id = this.$route.params.id;
			const data = await getDocument('productservice', id, 'productservice_primary');
			if (data.length) this.photo = data[0];
			this.getCarousel();
		},

		getCarousel: async function () {
			const id = this.$route.params.id;
			const data = await getDocument('productservice', id, 'gallery');
			if (data.length) {
				this.carousel = data;
				if (this.photo) {
					this.carousel.unshift(this.photo);
				}
			}
		},

		embedVideo: function (url) {
			const urlSplitted = url.split('/');
			const host = urlSplitted[2];
			if (host === 'www.youtube.com') {
				return 'https://www.youtube.com/embed/' + this.getYoutubeId(url);
			} else if (host === 'vimeo.com') {
				return 'https://player.vimeo.com/video/' + urlSplitted[urlSplitted.length - 1];
			} else return null;
		},

		getYoutubeId: function (url) {
			const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
			const match = url.match(regExp);

			return match && match[2].length === 11 ? match[2] : null;
		}
	},
	computed: {
		getCompProduct() {
			return this.infoProducto;
		},

		getCompExpositor() {
			return this.infoExpositor.exhiname;
		},
		getCompExpositorId() {
			return this.infoExpositor.exhiid;
		},

		getNombreProducto() {
			return this.infoProducto && this.infoProducto.pstrname ? this.infoProducto.pstrname : '';
		}
	}
};
</script>


<style lang="scss" scoped>
.icon {
	float: right;
}

.product-details-main__breadcrums {
	padding-bottom: 0.813rem;

	&-link {
		color: #575e60;
		text-decoration: none;
	}

	&-separator {
		padding: 0 0.75rem;
	}
}

.product-details-main__container {
	&-top {
		display: flex;
		justify-content: space-between;

		&-left {
			display: flex;
			align-items: center;

			&-logo {
				display: flex;
				align-items: center;
				padding-right: 1.5rem;

				&-image {
					width: 5.875rem;
					max-height: 4rem;
					object-fit: cover;
				}
			}

			&-text {
				&-title {
					font-family: Cabin;
					font-weight: bold;
					font-size: 32px;
					color: #293133;
				}

				&-subtitle {
					font-weight: normal;
					font-size: 14px;
					color: #575e60;
				}
			}
		}

		&-right {
			display: flex;
			align-items: center;

			&-button {
				border-radius: 8px;
				padding: 0.75rem 1.25rem;
				max-height: 2.5rem;
				display: flex;
				align-items: center;
				margin: 0 0.25rem;

				&--blue {
					background-color: #2498ff !important;
					font-family: Cabin;
					font-size: 1rem;
					color: #fff;
				}

				&--white {
					border: 1px solid #d2d7d9;
					font-family: Cabin;
					font-weight: bold;
					font-size: 1rem;
					color: #575e60;
				}
			}
		}
	}

	&-middle {
		padding: 1.875rem 0;
		display: flex;
		justify-content: center;

		&-image {
			border-radius: 8px;
			width: 100%;
			aspect-ratio: 16/9;
			object-fit: cover;
		}
	}
}
.exhibitor-details-products__list {
	display: flex;
}

.exhibitor-details-products__item {
	border: 1px solid #d2d7d9;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0.313rem;
	width: 32%;
	height: 21rem;

	&-top {
		&-image {
			height: 9.5rem;
			width: 100%;

			&-img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 8px 8px 0 0;
			}

			&-PREMIUM {
				color: #fff;
				background-color: #d4af37;
				border-radius: 6px;
				padding: 0.125rem 0.375rem;

				height: 1.4rem;
				display: flex;
				align-items: center;
				font-family: Roboto;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 21px;
			}

			&-buttons {
				display: flex;
			}
		}

		&-text {
			padding: 10.5rem 0 1rem 1rem;
			padding-right: 1rem;

			&-title {
				font-family: Cabin;
				font-weight: bold;
				font-size: 16px;
				color: #293133;
				padding-bottom: 0.25rem;

				&-link {
					text-decoration: none;
				}
			}

			&-sector {
				font-weight: normal;
				font-size: 14px;
				color: #575e60;
			}
		}
	}

	&-bottom {
		width: 35%;
		& > img {
			width: 100%;
			max-height: 2.5rem;
			object-fit: cover;
			margin: 0 0 1rem 1rem;
		}
	}
}
.close {
	width: 12x !important;
	height: 12px !important;
	cursor: pointer;
}

.product-details-main__container-top {
	@media only screen and (max-width: 960px) {
		display: contents;
	}
}

.product-details-main__container-top-right {
	@media only screen and (max-width: 960px) {
		padding-top: 0.5rem;
	}
}

.product-details-main__container-middle {
	@media only screen and (max-width: 960px) {
		padding-top: 0.5rem;
	}	
}
</style>
