<template>
	<div v-if="documents.length" class="product-details-documents">
		<div class="product-details-documents-title">
			<img :src="IconDocument" alt="icon" />
			<h3 class="product-details-documents-title-text">{{ $t('products.documents') }}</h3>
		</div>
		<div class="product-details-documents-container">
			<div v-for="(chunk, index) in documentsChunks" :key="index" class="product-details-documents-row">
				<div v-for="document in chunk" :key="document.id" class="product-details-documents-item" :title="document.description">
					<img :src="IconDownload" alt="download" />
					<a class="product-details-documents-item-name" :href="document.url" target="_blank">{{ document.description }}</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getDocument } from '@/api/common.js';
import _ from 'lodash';

export default {
	name: 'ProductDetailsDocuments',

	props: {
		product: {}
	},

	data() {
		return {
			IconDocument: require('@/assets/icons/document.svg'),
			IconDownload: require('@/assets/icons/download.svg'),

			documents: [],
			rowSize: 3
		};
	},

	watch: {
		product: 'getDocuments',
		'$store.state.activeLanguage': 'getDocuments'
	},

	computed: {
		documentsChunks() {
			return _.chunk(Object.values(this.documents), this.rowSize);
		}
	},

	methods: {
		getDocuments: async function () {
			this.documents = [];
			let oneProduct = null;
			oneProduct = await getDocument('productservice', this.$route.params.id, 'productservice_documents');
			for (let x = 0; x < oneProduct.length; x++) {
				if (oneProduct[x].language === this.$store.state.activeLanguage.toLowerCase() || oneProduct[x].language === null)
					this.documents.push(oneProduct[x]);
			}

			this.documents.sort((a, b) =>
				a.filename.toLowerCase() > b.filename.toLowerCase() ? 1 : b.filename.toLowerCase() > a.filename.toLowerCase() ? -1 : 0
			);
		}
	}
};
</script>


<style lang="scss" scoped>
.product-details-documents {
	padding-bottom: 2rem;
}

.product-details-documents-title {
	display: flex;
	padding-bottom: 0.812rem;

	&-text {
		padding-left: 0.75rem;
		font-family: Cabin;
		font-weight: bold;
		font-size: 26px;
		color: #293133;
	}
}

.product-details-documents-row {
	display: flex;
}

.product-details-documents-item {
	display: flex;
	align-items: center;
	border: 1px solid #d2d7d9;
	border-radius: 8px;
	padding: 0.5rem 1.125rem;
	margin: 0.313rem;
	max-width: 32%;

	&-name {
		padding-left: 0.5rem;
		font-family: Cabin;
		font-weight: bold;
		font-size: 16px;
		color: #575e60;
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		text-decoration: none;
	}
}
</style>
